import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';
import { BlueBox } from 'src/components/BlueBox';

const AugmentedRecruitmentPage = () => (
  <Layout className="recruitment">
    <SEO title="Augmented Recruitment" />
    <div className="container pt-2 pb-4">
      <PageTitle className="mb-2 mb-lg-8" title='Augmented Recruitment/RPO' />
      <h2 className="mb-3">How do you know you need RPO?</h2>
      <div className="row">
        <div className="col-6 col-lg-4 col-xl-2">
          <StaticImage
            src="../assets/images/recruitment/showing.png"
            alt="Graphic"
            layout="fullWidth"
          />
        </div>
        <BlueBox text={<span><strong>Ambitious</strong> hiring goals and <strong>limited</strong> time</span>} />
        <BlueBox text={<span>Recruiting team<strong> overloaded</strong> or recruiter <strong>missing</strong></span>} />
        <BlueBox text={<span><strong>Extensive</strong> hiring process</span>} />
        <BlueBox text={<span>Growing rate of <strong>rejected offers</strong></span>} />
        <BlueBox text={<span><strong>Fast feedback</strong> loop missing</span>} />
      </div>
    </div>
    <div className="bg-white">
      <div className="container pt-3 pt-md-5">
        <h2 className="mb-3">We will help you with...</h2>
        <div className="row">
          <div className="col-4 col-lg-4 col-xl-2 mb-4">
            <StaticImage
              className="mb-3"
              src="../assets/images/recruitment/experience.png"
              alt="Graphic"
              layout="fullWidth"
            />
            <h3>Candidate experience</h3>
          </div>
          <div className="col-4 col-lg-4 col-xl-2 mb-4">
            <StaticImage
              className="mb-3"
              src="../assets/images/recruitment/growth.png"
              alt="Graphic"
              layout="fullWidth"
            />
            <h3>Growth</h3>
          </div>
          <div className="col-4 col-lg-4 col-xl-2 mb-4">
            <StaticImage
              className="mb-3"
              src="../assets/images/recruitment/support.png"
              alt="Graphic"
              layout="fullWidth"
            />
            <h3>HR support</h3>
          </div>
          <div className="col-4 col-lg-4 col-xl-2 mb-4">
            <StaticImage
              className="mb-3"
              src="../assets/images/recruitment/intelligence.png"
              alt="Graphic"
              layout="fullWidth"
            />
            <h3>Market intelligence</h3>
          </div>
          <div className="col-4 col-lg-4 col-xl-2 mb-4">
            <StaticImage
              className="mb-3"
              src="../assets/images/recruitment/partnership.png"
              alt="Graphic"
              layout="fullWidth"
            />
            <h3>Partnership</h3>
          </div>
          <div className="col-4 col-lg-4 col-xl-2 mb-4">
            <StaticImage
              className="mb-3"
              src="../assets/images/recruitment/channels.png"
              alt="Graphic"
              layout="fullWidth"
            />
            <h3>Candidate channels</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-3 py-md-7">
      <h2 className="mb-3">How it works</h2>
      <p className="mb-3 text-justify">
        Are you missing dozens of new colleagues or building a new 5, 6,
        ... headed team? We know how to make the whole recruitment process
        efficient, transparent and get the best cost per hire ratio!
      </p>
      <p className="mb-3 text-justify">
        <strong>
          One of our Consultants becomes part of your team with only one KPI
          and that is to meet your hiring goals.
        </strong>
      </p>
      <p className="text-justify">
        Augmented recruitment or the so-called Recruitment Process Outsourcing
        is a hiring solution when you outsource the management of the recruitment
        process (in whole or partially) to us to lower costs, increase efficiency,
        and receive scalability benefits.
      </p>
    </div>
  </Layout>
);

export default AugmentedRecruitmentPage;
